@import '../../styles/dist/index.scss';

.page-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: map-get($zIndex, 'header');
  transition: box-shadow 0.3s ease;

  &.shadow {
    box-shadow: 0 5px 15px 0 rgba(#000, 0.5);
  }
}

.page-header .top-line {
  height: 40px;
  background-color: map-get($colors, 'orange');
  color: #fff;

  font: 800 1rem/40px Helvetica, sans-serif;
  text-align: right;
  text-transform: uppercase;

  @media screen and (max-width: $tablet-sm) {
    height: 60px;
    line-height: 60px;
  }

  p {
    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  a {
    display: none;
    position: relative;

    color: #fff;
    font-size: 24px;

    text-decoration: none;

    @media screen and (max-width: $tablet-sm) {
      display: inline-block;
    }

    &::before {
      content: '';
      background-image: url('../../static/phone-icon.svg');
      background-size: cover;
      background-position: center;
      background-size: cover;
      width: 18px;
      height: 18px;

      position: absolute;
      top: 50%;
      left: -25px;
      transform: translateY(-50%);

      @media screen and (max-width: $tablet-sm) {
        width: 25px;
        height: 25px;
        left: -35px;
      }
    }
  }
}

.page-header .action-line {
  padding: 10px 0;
  background-color: #fff;

  .container {
    @include align-center;
    justify-content: space-between;
  }

  &__logo {
    display: block;
    max-width: 150px;

    @media screen and (max-width: $tablet-sm) {
      max-width: 110px;
    }

    img {
      object-fit: cover;
      vertical-align: top;
    }
  }

  &__links {
    @include align-center;

    a {
      display: inline-block;

      &:first-of-type {
        position: relative;
        margin: 0 40px 0 0;

        font: 800 30px/1 Helvetica, sans-serif;
        color: map-get($colors, 'text');

        text-decoration: none;

        @media screen and (max-width: $tablet-sm) {
          display: none;
        }

        &::before {
          @include backgroundImageCover('../../static/phone-icon_orange.svg');
          @include squareSize(30px); // width: 30px;

          content: '';
          position: absolute;
          top: 50%;
          left: -45px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
