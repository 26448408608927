@import '../../styles/dist/index.scss';

#pricing {
  scroll-margin-top: 150px;
}

.pricing {
  margin: map-get($section-margin, 'desktop');

  @media screen and (max-width: $tablet-sm) {
    margin: map-get($section-margin, 'mobile');
  }

  &__rates {
    display: flex;
    gap: 60px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    @supports (not (translate: none)) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 60px;

      @media screen and (max-width: $tablet-sm) {
        grid-template-columns: repeat(1, 1fr);
        rows-gap: 40px;
      }
    }

    &--item {
      max-width: 50%; // need remove if more than 1 rate
      margin: 0 auto;
      padding: 30px;
      width: 100%;
      box-shadow: 0px 0px 20px 0px rgba(7, 7, 7, 0.12);
      border-radius: 40px;

      display: flex;
      flex-direction: column;

      h4 {
        font-size: 30px;
        font-weight: 800;
        line-height: 120%;
        text-transform: uppercase;
      }

      .includes,
      .additional {
        list-style-type: disc;
        padding-left: 30px;
        line-height: 200%;
      }

      .includes {
        margin: 50px 0 0;
      }

      .additional {
        margin: 10px 0 0;
        flex-grow: 1;
      }

      & > p {
        margin: 30px 0 0;
        font-weight: 800;
        font-size: 22px;
      }

      .price {
        margin: 30px 0 0;

        p {
          font-size: 50px;
          line-height: 120%;
          color: map-get($colors, 'orange');
          font-weight: 800;

          &.price-without-discount {
            color: #777;
            font-size: 36px;
            font-weight: 600;
            text-decoration-line: line-through;
          }
        }
      }

      .links {
        margin: 30px 0 0;
        display: flex;
        gap: 30px;

        @media screen and (max-width: $tablet-sm) {
          gap: 15px;
        }

        @supports (not (translate: none)) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 30px;

          @media screen and (max-width: $tablet-sm) {
            column-gap: 15px;
          }
        }

        & > * {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          max-width: 50%;
          width: 100%;
          padding: 8px 12px;

          height: 60px;

          color: #fff;
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 600;

          background-color: map-get($colors, 'orange');

          text-decoration: none;

          @media screen and (max-width: $tablet-sm) {
            font-size: 14px;
          }

          @supports (not (translate: none)) {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.widget-form {
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  input {
    height: 60px;
    width: 100%;
    padding: 0 15px;
  }

  button[type='submit'] {
    border: none;
    padding: 8px 12px;
    height: 60px;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;

    background-color: map-get($colors, 'orange');
  }
}

@supports (not (translate: none)) {
  .links > a:not(:last-of-type) {
    margin-right: 5%;
  }
}
