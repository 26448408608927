@import '../../styles/dist/index.scss';

.why-we {
  margin: map-get($section-margin, 'desktop');

  @media screen and (max-width: $tablet-sm) {
    margin: map-get($section-margin, 'mobile');
  }

  .container {
    display: flex;
    gap: 60px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    @supports (not (translate: none)) {
      .why-we__inner {
        max-width: 100%;
        width: 100%;
        margin-right: 60px;

        @media screen and (max-width: $tablet-sm) {
          margin-right: 0;
        }
      }
    }
  }

  &__content {
    @include grid;
    margin: 70px 0 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 50px 0 0;
    }

    &--card {
      .header {
        @include align-center;
        gap: 10px;

        @media screen and (max-width: $tablet-sm) {
          gap: 20px;
        }

        @supports (not (translate: none)) {
          .icon {
            margin-right: 10px;

            @media screen and (max-width: $tablet-sm) {
              margin-right: 20px;
            }
          }
        }

        .icon {
          background-color: map-get($colors, 'orange');
          border-radius: 50%;

          height: 80px;
          max-width: 80px;
          width: 100%;

          @include align-center;
          justify-content: center;

          img {
            @include squareSize(45px);
            object-fit: contain;
          }
        }

        h4 {
          font-size: 26px;
          font-weight: bold;
          line-height: 35px;
          text-transform: uppercase;
        }
      }

      p {
        margin: 25px 0 0;
        font-size: 18px;
        line-height: 155%;
      }
    }
  }

  &__form {
    max-width: 400px;
    width: 100%;
    background-color: map-get($colors, 'blue');
    padding: 40px;
    color: #fff;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;

    @media screen and (max-width: $tablet-sm) {
      padding: 40px 20px;
    }

    h4 {
      font-size: 22px;
      font-weight: 700;
      text-transform: uppercase;

      color: #fff;
      text-align: center;
    }

    input {
      &:not(input[type='submit']) {
        background-color: rgba(#000, 0.3);
        color: #fff;
      }

      &[type='submit'] {
        font-weight: bold;

        &:hover {
          background-color: rgba(map-get($colors, 'orange'), 1);
          box-shadow: 0 0 8px 1px rgba(map-get($colors, 'orange'), 1);
          color: #000;
        }
      }
    }
  }
}
