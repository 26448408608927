@mixin space-between {
  display: flex;
  justify-content: space-between;
}

@mixin space-around {
  display: flex;
  justify-content: space-around;
}

@mixin align-center {
  display: flex;
  align-items: center;
}

@mixin scale {
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
}

@mixin backgroundImageCover($url: '') {
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@mixin squareSize($size) {
  width: $size;
  height: $size;
}

@mixin grid($row-gap: 40px, $column-gap: 40px, $rows: 1fr, $columns: repeat(2, 1fr)) {
  display: grid;
  grid-template-rows: $rows;
  grid-template-columns: $columns;
  grid-row-gap: $row-gap;
  grid-column-gap: $column-gap;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
