@import '../../styles/dist/index.scss';

.cases {
  margin: map-get($section-margin, 'desktop');

  @media screen and (max-width: $tablet-sm) {
    margin: map-get($section-margin, 'mobile');
  }

  .case-card {
    position: relative;
    margin: 30px 15px 30px;
    max-width: 90%;

    border-radius: 20px;
    overflow: hidden;

    box-shadow: 0 0 20px 0 rgba(7, 7, 7, 0.5);

    iframe {
      width: 100%;
      height: 300px;
      vertical-align: top;
    }
  }
}

.slick-slider {
  .slick-next,
  .slick-prev {
    @include squareSize(60px);

    background: #fff;
    border: 1px solid map-get($colors, 'green');
    border-radius: 50%;

    @include align-center;
    justify-content: center;

    position: absolute;
    right: 0;
    top: -80px;

    cursor: pointer;

    &::before {
      content: '';
      width: 33px;
      height: 16px;

      @include backgroundImageCover('https://bigcityhomeservice.com/wp-content/uploads/2022/08/Arrow-2.svg');

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: $tablet-sm) {
      top: -30px;

      @include squareSize(45px);
    }
  }

  .slick-next {
    right: 20px;

    @media screen and (max-width: $tablet-sm) {
      right: 0;
    }
  }

  .slick-prev {
    right: 100px;
    left: unset;

    &::before {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    @media screen and (max-width: $tablet-sm) {
      right: auto;
      left: 0;
    }
  }
}
