@use 'scss-reset/reset';

@import 'slick-carousel/slick/slick';
@import 'slick-carousel/slick/slick-theme';

@import './dist/variables.scss';
@import './dist/mixins.scss';

// html {
//   height: 100% !important;
// }

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: #fff;
  font: 400 16px/1.24 'Helvetica', sans-serif;
  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  display: flex;
  flex-direction: column;

  position: relative;
  overflow-x: hidden;

  main {
    flex: 1 0 auto;
    padding: 140px 0 0;
    transition: all 0.3s ease;

    // @media screen and (max-width: $tablet-sm) {
    // }
  }
}

a {
  cursor: pointer;
}

textarea {
  resize: none;
}

.button {
  display: block;
  width: 250px;

  font: 400 16px/44px 'Helvetica', sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #fff;

  border-radius: 60px;

  transition: background-color 0.3s ease;

  @media screen and (max-width: $tablet-sm) {
    width: 200px;
    font-size: 14px;
  }

  &.blue {
    background-color: map-get($colors, 'blue');

    &:hover {
      background-color: map-get($colors, 'darkblue');
    }
  }

  &.orange {
    background-color: map-get($colors, 'orange');

    &:hover {
      background-color: map-get($colors, 'darkOrange');
    }
  }
}

.section-title {
  @include align-center;
  position: relative;
  margin: 0 0 60px;
  padding-left: 40px;

  color: map-get($colors, 'text');
  font-weight: 800;
  font-size: 52px;
  text-transform: uppercase;

  @media screen and (max-width: $tablet-sm) {
    font-size: 32px;
  }

  &::before {
    content: '';
    background-color: map-get($colors, 'orange');
    height: 100%;
    left: 0;
    position: absolute;
    width: 10px;
  }
}

.legal-info {
  padding: 60px 0 0;

  p,
  ul {
    margin: 0 0 15px;
  }

  strong {
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
    padding-left: 30px;
  }
}

.thank-you {
  padding: 120px 0;
  @include align-center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  max-width: 650px;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: $tablet-sm) {
    padding: 60px 15px;
    max-width: unset;
  }

  .title {
    font-size: 36px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 28px;
    font-weight: bold;
  }

  p {
    font-size: 18px;
  }
}
