@import '../../styles/dist/index.scss';

.trust {
  margin: map-get($section-margin, 'desktop');

  @media screen and (max-width: $tablet-sm) {
    margin: map-get($section-margin, 'mobile');
  }

  &__content {
    p {
      line-height: 125%;

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }
}
