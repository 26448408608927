@import '../../styles/dist/index.scss';

.modules {
  padding: 100px 0;
  margin: map-get($section-margin, 'desktop');

  @include backgroundImageCover('../../static/modules-bg.png');

  @media screen and (max-width: $tablet-sm) {
    margin: map-get($section-margin, 'mobile');
  }

  .section-title {
    color: #fff;
  }

  &__list {
    display: flex;

    flex-wrap: wrap;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      gap: 20px;
      justify-content: center;
    }

    @supports (not (translate: none)) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 40px;

      @media screen and (max-width: $tablet-sm) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 20px;
      }
    }

    &--item {
      background-color: rgba(#fff, 0.85);
      box-shadow: 0 0 20px 0 rgba(#fff, 0.8);

      text-align: center;
      padding: 12px 8px;

      max-width: 30%;
      width: 100%;

      p:first-of-type {
        font-weight: bold;
      }

      @include scale;

      @media screen and (max-width: $tablet-sm) {
        max-width: 40%;
        height: 100px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      @supports (not (translate: none)) {
        max-width: 100%;
      }
    }
  }
}
