@import '../../styles/dist/index.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 9999;

  .overlay {
    width: inherit;
    height: inherit;

    background-color: rgba(#000, 0.6);
  }

  &__inner {
    width: 30%;
    background-color: #fff;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    padding: 30px;

    @media screen and (max-width: $tablet-sm) {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      margin: 0 auto;

      &:not([type='submit']) {
        background-color: #fff !important;
        border: 1px solid #000 !important;
        color: #000 !important;
        &::placeholder {
          color: #000 !important;
        }
      }
    }

    .data-protection-label {
      svg {
        fill: #000;
      }
    }
  }
}

.close-btn {
  width: 40px;
  height: 40px;

  background-color: #fff;
  border-radius: 50%;

  position: absolute;
  right: 20px;
  top: 20px;

  cursor: pointer;

  @include align-center;
  justify-content: center;

  &::after {
    content: '\00d7';
    font-size: 30px;
    color: map-get($colors, 'orange');

    transition: all 0.3s ease;
  }

  &:hover {
    &::after {
      transform: rotate(360deg);
    }
  }
}
