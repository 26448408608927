$colors: (
  'blue': #2a6ad1,
  'darkblue': #0c47a5,
  'text': #070707,
  'orange': #fb5f0c,
  'darkOrange': #d14f08,
);

$section-margin: (
  'desktop': 120px 0 0,
  'mobile': 60px 0 0,
);

$zIndex: (
  'header': 100,
  'modal': 999,
);

$desktop-md: 1440px;
$desktop-sm: 1200px;
$tablet-lg: 1024px;
$tablet-md: 900px;
$tablet-sm: 768px;
