@import '../../styles/dist/index.scss';

.form-wrapper {
  form {
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
      width: unset;
    }

    & *::placeholder {
      color: #fff;
    }

    & > * {
      &:not(input[type='submit']) {
        color: map-get($colors, 'text');
        padding: 0 20px;
        line-height: 50px;

        border-radius: 10px;
        border: 1px solid #fff;
        outline: none;

        font-size: 18px;
      }

      &:not(:last-child) {
        margin: 0 0 20px;
      }
    }

    input,
    select {
      display: block;
      max-width: 100%;
      height: 50px;
    }

    .button {
      background-color: map-get($colors, 'orange');
      border: none;
      font-family: 'Open Sans', sans-serif;

      &:hover {
        cursor: pointer;
        background-color: rgba(map-get($colors, 'orange'), 0.8);
      }
    }
  }

  .data-protection-label {
    margin: 15px 0 0;
    @include align-center;
    font-size: 14px;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      fill: #fff;
    }
  }
}
