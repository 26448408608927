@import '../../styles/dist/index.scss';

.hero {
  &__inner {
    display: flex;
    gap: 50px;
    position: relative;
    padding: 30px 0 0;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 30px;
    }

    &::before {
      content: '';
      background: rgba(251, 102, 22, 0.1);
      border-radius: 50%;

      @include squareSize(1200px);

      position: absolute;
      top: -350px;
      left: 700px;
      z-index: -1;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    @supports (not (translate: none)) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 50px;

      @media screen and (max-width: $tablet-sm) {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 30px;
      }
    }

    & > div {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
        width: unset;
      }

      @supports (not (translate: none)) {
        max-width: 100%;

        @media screen and (max-width: $tablet-sm) {
          max-width: 100%;
          width: unset;
        }
      }
    }
  }

  &__content {
    @media screen and (max-width: $tablet-sm) {
      text-align: center;
    }

    h1 {
      font-size: 72px;
      font-weight: bold;
      line-height: 130%;
      text-transform: uppercase;

      position: relative;

      @media screen and (max-width: $tablet-sm) {
        font-size: 42px;
      }

      &::before {
        content: '';
        @include backgroundImageCover('../../static/subtitle-span.svg');
        height: 10px;
        width: 146px;

        position: absolute;
        top: -20px;
        left: 0;

        @media screen and (max-width: $tablet-sm) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .subtitle {
      font-size: 28px;
      margin: 15px 0 0;
    }

    .start_from {
      margin: 30px 0 0;
      font-size: 32px;
      font-weight: bold;
      text-transform: uppercase;

      @media screen and (max-width: $tablet-sm) {
        font-size: 30px;
      }
    }

    .buttons {
      @include align-center;
      margin: 30px 0 0;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px auto 0;
        flex-direction: column;
      }
    }

    .button {
      &.blue {
        margin: 0 15px 0 0;

        @media screen and (max-width: $tablet-sm) {
          margin: 0 0 15px 0;
        }
      }
    }
  }

  &__image {
    @include backgroundImageCover;
    min-height: 500px;
    width: 100%;

    @media screen and (max-width: $tablet-sm) {
      min-height: 300px;
    }
  }
}
