@import '../../styles/dist/index.scss';

.about {
  margin: map-get($section-margin, 'desktop');

  @media screen and (max-width: $tablet-sm) {
    margin: map-get($section-margin, 'mobile');
  }

  &__block {
    .section-title {
      margin: 0 0 30px;
    }

    .image {
      max-width: 50%;
      width: 100%;

      @include backgroundImageCover;

      min-height: 300px;

      border-radius: 10px;
      box-shadow: 10px -10px 0 0 map-get($colors, 'orange');

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        margin: 0 0 30px;
      }

      @supports (not (translate: none)) {
        max-width: 100%;
      }
    }

    .content {
      width: 100%;

      display: flex;
      gap: 40px;

      @media screen and (max-width: $tablet-sm) {
        flex-direction: column;
      }

      @supports (not (translate: none)) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 40px;

        @media screen and (max-width: $tablet-sm) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      ul {
        list-style-type: disc;
        padding: 30px 0 30px 30px;

        @media screen and (max-width: $tablet-sm) {
          padding: 0 0 0 30px;
        }

        li {
          &:not(:last-of-type) {
            margin: 0 0 15px;
          }
        }
      }

      div {
        max-width: 50%;
        width: 100%;

        @media screen and (max-width: $tablet-sm) {
          max-width: unset !important;
        }

        @supports (not (translate: none)) {
          max-width: 100%;
        }
      }
    }

    &:nth-of-type(2n) {
      .content {
        flex-direction: row-reverse;

        @media screen and (max-width: $tablet-sm) {
          flex-direction: column;
        }
      }
    }

    &:not(:last-of-type) {
      margin: 0 0 60px;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 0;
      }
    }
  }
}
