@import '../../styles/dist/index.scss';

.page-footer {
  margin: 60px 0 0;
  padding: 90px 0 30px;

  @include backgroundImageCover('../../static/footer-bg.png');

  @media screen and (max-width: $tablet-sm) {
    padding: 60px 0 90px;
  }

  &__inner {
    text-align: center;
  }

  &__logo {
    display: inline-block;
    height: 100px;
    @include scale;

    img {
      object-fit: cover;
      height: inherit;
      width: auto;
      vertical-align: top;
    }

    @media screen and (max-width: $tablet-sm) {
      text-align: center;
      width: 100%;
    }
  }

  &__contacts {
    margin: 60px 0 0;

    li {
      position: relative;
      text-indent: 30px;
      color: #fff;
      font-size: 18px;

      // &::before {
      //   content: '';
      //   position: absolute;
      //   left: 0;

      //   background-size: contain !important;

      //   @include squareSize(20px);
      // }

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }

      // &:first-of-type::before {
      //   @include backgroundImageCover('../../static/location-icon.svg');
      // }

      // &:nth-of-type(2)::before {
      //   @include backgroundImageCover('../../static/phone-icon_white.svg');
      // }

      // &:nth-of-type(3)::before {
      //   @include backgroundImageCover('../../static/envelope-icon.svg');
      // }

      // &:last-of-type::before {
      //   @include backgroundImageCover('../../static/clock-icon.svg');
      // }

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .insta-link {
      display: flex;
      justify-content: center;

      svg {
        width: 30px;
        height: 30px;
      }

      @include scale;
    }
  }

  .internal-links {
    margin: 30px 0 0;
    display: inline-block;
    flex-direction: column;

    a {
      display: block;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }

  .underline {
    margin: 30px 0 0;
    padding: 30px 0 0;

    border-top: 1px solid #fff;
    text-align: center;

    color: #fff;
    font-size: 14px;

    a {
      display: block;
      margin: 15px 0 0;
      color: #fff;
      text-decoration: none;

      strong {
        font-weight: bold;
      }

      &:hover {
        strong {
          text-decoration: underline;
        }
      }
    }
  }
}
