@import '../../styles/dist/index.scss';

.after-school {
  margin: map-get($section-margin, 'desktop');

  @media screen and (max-width: $tablet-sm) {
    margin: map-get($section-margin, 'mobile');
  }

  &__inner {
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 20px;
    }

    @supports (not (translate: none)) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;

      @media screen and (max-width: $tablet-sm) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
      }
    }

    .image {
      max-width: 50%;
      width: 100%;

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      min-height: 400px;

      box-shadow: 10px -10px 0 0 #fb5f0c;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }

      @supports (not (translate: none)) {
        max-width: 100%;
      }
    }
  }

  &__content {
    max-width: 50%;
    width: 100%;
    line-height: 150%;

    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
    }

    @supports (not (translate: none)) {
      max-width: 100%;
    }

    .section-title {
      line-height: 1;
    }
  }
}
